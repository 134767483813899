<template>
  <v-col
    v-if="show"
  >
    <card
      class="pa-1 ma-5"
      style="min-height: 520px;"
      outlined
    >
      <v-card-title v-if="isTitle">
        <v-icon
          large
          left
          :color="statusObj[status].color"
        >
          fa fa-file-text-o
        </v-icon>
        <span
          class="text-h4 font-weight-black font-weight-light"
          :color="statusObj[status].color"
        >
          {{ statusObj[status].title }}
        </span>
      </v-card-title>
      <v-alert
        text
        close-icon="fa fa-close"
        icon="fa4 fa-bar-chart"
        dense
        class="mt-4 py-1"
        :color="statusObj[status].color"
      >
        有<strong> {{ projectTotal }}</strong>个项目{{ statusObj[status].title }}.
      </v-alert>
      <v-simple-table>
        <thead>
          <tr>
            <th>项目名称</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ project.name }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                :to="`report-details/${project.formId}/${project.id}`"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                审核
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-btn
          block
          color="blue darken-2"
          outlined
          :to="`/examine-list/?astatus=${status}`"
        >
          查看更多
        </v-btn>
      </v-card-actions>
    </card>
  </v-col>
</template>

<script>
  export default {
    props: {
      status: {
        type: Number,
        default: 0,
      },
      type: {
        type: String,
        default: 'report',
      },
      isTitle: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      const statusObj = {}
      statusObj['0'] = {
        title: '待审核',
        icon: 'mdi-email',
        color: 'blue darken-3',
      }
      statusObj['10'] = {
        title: '已通过',
        icon: 'mdi-email',
        color: 'success',
      }
      statusObj['-5'] = {
        title: '待完善',
        icon: 'mdi-email',
        color: 'red darken-3',
      }
      statusObj['-10'] = {
        title: '彻底驳回',
        icon: 'mdi-email',
        color: 'red darken-3',
      }
      return {
        statusObj: statusObj,
        // 待审核
        show: true,
        projectList: [],
        projectTotal: 0,
        formIds: '',
      }
    },
    mounted () {
      if (JSON.parse(localStorage.newForms).length) {
        const list = JSON.parse(localStorage.newForms)[0].children
        const formIds = []
        list.map(val => {
          formIds.push(val.id)
        })
        this.formIds = formIds.toString()
      }
      this.getAuditProject()
    },
    methods: {
      // 获取下级提报的待审核项目
      getAuditProject () {
        this.$axios.get('/reports/audit2', {
          params: {
            page: 0,
            size: 5,
            astatus: this.status,
            formId: this.formIds,
          },
        })
          .then(res => {
            console.log('res', res);

            const resData = res.data.data
            this.projectList = resData.content
            this.projectTotal = resData.totalElements
            if (!resData.totalElements) {
              // this.show = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
